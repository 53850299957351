<template>
  <article class="code-page">
    <div v-if="isLoading" class="loader"></div>

    <div class="code-header" v-if="!isLoading">
      <span v-for="(title, index) in articleHeaderTitles" :key="index" @click="selectArticleType(title)" class="header-title" :class='{"selected": isSelectedArticleType(title)}'>
        {{title}}
      </span>
    </div>

    <section class="articles" v-if="!isLoading">

      <section class="column left">
        <h1>{{menuTitle}}</h1>
        <section v-for="(article, index) in articles" :key="index" class="menu-entry">
          <div>
            <div @click="expandCategory(article.category)" class="category">
              {{ article.category }}
            </div>
            <div
              v-for="(articlesub, index) in article.articles" :key="index"
              @click="showArticle(articlesub)"
              class="subcategory" :class='{"hidden": !isSelectedCategory(article.category)}'
            >
              {{ articlesub.subCategory }}
            </div>
          </div>
        </section>
      </section>

      <section class="column center">
        <section>
          <div v-if="hasSelectedArticle" v-html="selectedArticle.articleSetup.text"></div>
        </section>
      </section>

      <section class="column right">

        <section v-if="hasSelectedArticle" class="article">
          <div v-for="(example, index) in selectedArticle.articleExamples" :key="index"> 
            <div v-html="example.text"></div>
          </div>
        </section> 

      </section>
    </section>

  </article>
</template>

<script>
import axios from "axios";

export default {
  name: 'code-articless',
  data () {
    return {
      selectedCategory: '',
      articleHeaderTitles: ['C#', 'Javascript', 'VueJS'],
      menuTitle: 'CSharp Articles',
      setupText: '',
      selectedArticleType: '',
      articles: [],
      selectedOption: 'Map',
      selectedArticle: {},
      isLoading: false
    }
  },
  async mounted () {
    this.isLoading = true;
    await this.getArticles();
    this.isLoading = false;
  },
  computed: {
    hasSelectedArticle () {
      return this.selectedArticle && Object.keys(this.selectedArticle).length > 0;
    }
  },
  methods: {
    async getArticles () {
      const response = await axios.get('https://streetsofsmashvilleapi.azurewebsites.net/api/apparticle?type=CSharp');
      this.articles = response.data;
      console.log('articles', this.articles);
    },
    selectArticleType (title) {
      this.selectedArticleType = title;
      console.log(this.selectedArticleType);
    },
    isSelectedArticleType (title) {
      return title === this.selectedArticleType;
    },
    isSelectedCategory (category) {
      return category == this.selectedCategory;
    },
    expandCategory (category) {
      this.selectedCategory = category;
    },
    showArticle (article) {
      console.log('a', article);
      this.selectedArticle = article;
    },
    handleKeyDown (e) {
      if (e.code === "Tab") {
        e.handled = true;
        e.preventDefault();
        const textArea = this.$refs.setupTextArea;
        const originalPosition = textArea.selectionStart;

        this.setupText = this.setupText.slice(0, textArea.selectionStart) + '\t' + this.setupText.slice(textArea.selectionStart);
        this.$nextTick(() => {
          textArea.setSelectionRange(originalPosition + 1, originalPosition + 1);
        });
      }
    }
  }
}
</script>

<style lang="scss">

    .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  /* width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: blue; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  body {
    overflow: hidden;

    
  }
  
  .code-page {
    font-size: 1.5em;
    margin-top: 2rem;

    .code-header {
      background: black;
      color: white;

      .header-title {
        padding-right: 2em;

        &.selected {
          color: white;
          background: blue;
        }
      }
    }

    .articles {
      display: flex;
      flex-direction: row;

      .column {
        height: 100vh;

        span, div {
          font-size: 21px;
          background: black;
          color: white;
        }
        .key {
          color: #00b2ff;
        }
        .sym {
          color: #ff00a1;
        }
        .op {
          color: red;
        }
        .comm {
          color: lime;
        }
        .str {
          color: #ff7a00;
        }
        .ylw {
          color: yellow;
        }
        
        .blu {
          color: #ffee00;
        }
        .result {
          background: gray;
          color: blue;
        }
        .access {
          color: #50b4ae;
        }
        .interface {
          color: #1ab46c;
        }
        .tab {
          padding-left: 2em;
        }

        .article {
          display: flex;
          flex-direction: column;
        }
      

        &.left {
          width: 10%;
          border-right: 10px solid black;
          margin-right: 1em;

          //padding-right: 1em;
          overflow-y: auto;

          .menu-entry {

            .category {
              padding-top: 0.5em;
              text-align: center;
              background: rgb(43, 43, 196);

            }
            
            .subcategory {
              background: rgb(134, 143, 182);

              &.hidden {
                display: none;
              }
            }
          }
        }

        &.center {
          width: 45%;

          textarea {
            max-width: 100%;
          }
        }

        &.right {
          width: 45%;
          
          padding-right: 0.25em;
          overflow-y: auto;
        }
      }
    }
  }
</style>
