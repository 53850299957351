<template>
  <div class="border">
  <div v-for="(nutrient, index) in nutrients" :key=index class="nutrient-area">
    <div class="nutrient-display">
      <div class="nutrient">
        <h4>{{ nutrient.id }}</h4>
        <h1>{{ nutrient.name }}</h1>
        <h2>{{ nutrient.displayName }}</h2>
        <h3>{{ nutrient.measurement }}</h3>
        <h3>{{ nutrient.measurementabbreviation }}</h3>
        <h3>{{ nutrient.alis }}</h3>
        <h3>{{ nutrient.displayOrder }}</h3>
        <h3>{{ nutrient.goalIsHigh }}</h3>
      </div>
    </div>
  </div>
  </div>
  
</template>

<script>
import axios from "axios";

export default {
  name: 'nutrients-view',
  data () {
    return {
      nutrients: []
    }
  },
  mounted () {
    this.getNutrients();
  },
  methods: {
    async getNutrients () {
      await axios.get('https://localhost:7241/api/nutrient').then(response => { this.nutrients = response.data });
    }
  }

}
</script>

<style lang="scss">

  .border {
    padding-left: 2em;
    padding-right: 2em;

    .nutrient-area {
      padding-top: 3em;
      display: inline-block;
      width: 25%;

      .nutrient-display {
        margin: 0 auto;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

        .nutrient {
          text-align: center;
        }
      }
    }
  }

</style>
