import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home-page.vue';
import NutrientAdmin from '../views/fitness/admin/nutrients-view.vue';
import IngredientMeasurementAdmin from '../views/fitness/admin/ingredient-measurement-view.vue';
import NutritionGoals from '../views/fitness/nutrition/nutrition-goals.vue';
import CodeArticles from '../views/code/articles.vue';
import CodeArticleCreator from '../views/code/admin/article-creator.vue';

import ReboundGame from '../views/games/rebound.vue';
import Dodgeball from '../views/games/dodgeball.vue';
import DodgeballXL from '../views/games/dodgeball-xl.vue';
import Hackman from '../views/games/hackman.vue';
import Quest from '../views/games/quest.vue';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/about-page.vue')
    },
    {
      path: '/fitness/nutrition/nutrition-goals',
      name: 'nutritiongoals',
      component: NutritionGoals
    },
    {
      path: '/fitness/admin/nutrients',
      name: 'nutrients',
      component: NutrientAdmin
    },
    {
      path: '/fitness/admin/ingredient-measurement',
      name: 'ingredientmeasurement',
      component: IngredientMeasurementAdmin
    },
    {
      path: '/code/articles',
      name: 'articles',
      component: CodeArticles
    },
    {
      path: '/code/admin/article-creator',
      name: 'article-creator',
      component: CodeArticleCreator
    },
    {
      path: '/games/rebound-game',
      name: 'rebound',
      component: ReboundGame
    },
    {
      path: '/games/dodgeball-game',
      name: 'dodgeball',
      component: Dodgeball
    },
    {
      path: '/games/dodgeball-xl-game',
      name: 'dodgeball-xl',
      component: DodgeballXL
    },
    {
      path: '/games/hackman-game',
      name: 'hackman',
      component: Hackman
    },
    {
      path: '/games/quest-game',
      name: 'quest',
      component: Quest
    }
  ]
})

export default router
