import { Rectangle } from '../shared/ccada';

export default class Character {
  constructor (name, xPos, yPos, width, height, portrait, overworldImage) {
    this.name = name;
    this.rectangle = new Rectangle(xPos, yPos, width, height, 50, 50, 'white');
    this.portrait = portrait;
    this.overworldImage = overworldImage;
  }

  draw (ctx) {
    this.rectangle.draw(ctx);
  }

  move (xMove, yMove, bounds) {
    this.rectangle.move(xMove, yMove, bounds);
  }

  intersects (shape) {
    return this.rectangle.positionMatches(shape);
  }

  isInBounds (direction, position) {
    return this.rectangle.isInBounds(direction, position);
  }
}