<template>
  <div class="border">
  <div v-for="(ingmeasure, index) in ingredientMeasurements" :key=index class="ingredient-measurements-area">
    <div class="ingredient-measurement-display">
      <div class="ingredient-measurement">
        <h4>{{ ingmeasure.id }}</h4>
        <h1>{{ ingmeasure.type }}</h1>
        <h2>{{ ingmeasure.unit }}</h2>
        <h3>{{ ingmeasure.unitQuantity }}</h3>
        <h3>{{ ingmeasure.abbreviation }}</h3>
      </div>
    </div>
  </div>
  </div>
  
</template>

<script>
import axios from "axios";

export default {
  name: 'ingredient-measurements-view',
  data () {
    return {
      ingredientMeasurements: []
    }
  },
  mounted () {
    this.getIngredientMeasurements();
  },
  methods: {
    async getIngredientMeasurements () {
      await axios.get('https://localhost:7241/api/ingredientmeasurement').then(response => { this.ingredientMeasurements = response.data });
    }
  }

}
</script>

<style lang="scss">

  .border {
    padding-left: 2em;
    padding-right: 2em;

    .ingredient-measurements-area {
      padding-top: 3em;
      display: inline-block;
      width: 25%;

      .ingredient-measurement-display {
        margin: 0 auto;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

        .ingredient-measurement {
          text-align: center;
        }
      }
    }
  }

</style>
