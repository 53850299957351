<template>
  <article class="quest-page">
    <section class="header">
      <span>{{ gameName }}</span>
    </section>

    <section class="body">
      <div class="column left">
      <div class="details">
        <div class="top">
          <img :src="portrait.src" />
        <h1>Test</h1>

        </div>
        <div class="bottom">
        <h1>Test</h1>

        </div>
      </div>
      </div>
      <div class="column right">
        <div class="game-container" id="GameContainer">
          <canvas id="QuestCanvas" class="game-canvas" width="500" height="500"></canvas>
        </div>
      </div>

    </section>
  </article>
  
</template>

<script>
import { drawString, Rectangle } from "./shared/ccada";
import Character from './quest/character';
import Cursor from './quest/cursor';

export default {
  name: 'quest-game',
  data () {
    return {
      canvas: {}, ctx: {}, gameBounds: {},
      gameName: 'Quest', gameView: 'TitleScreen',
      keyDelay: false, rightPressed: false, leftPressed: false, upPressed: false, downPressed: false,
      units: [Character],
      background: Image, 
      cursor: Cursor,
      portrait: Image,
      characters: []
    }
  },
  async mounted () {
    setInterval(this.gameMasterHandler, 10);
    document.addEventListener("keydown", this.keyDownHandler, false);
    document.addEventListener("keyup", this.keyUpHandler, false);

    this.background = new Image();
    this.background.src = require('../../assets/games/media/images/Chapter2a.png'),

    this.cursor = new Cursor(new Image(), 50, 50, 50, 50, 50);
    this.cursor.image.src = require('../../assets/games/media/images/cursor.png'),

    this.portrait = new Image();
    this.portrait.src = require('../../assets/games/media/images/HectorPortrait.png');


    this.unknownPortrait = new Image();
    this.unknownPortrait.src = require('../../assets/games/media/images/UnknownPortrait.png');

    this.characters = require('../games/quest/characterData.json');
    console.log('characters', this.characters[0])
    this.characters.forEach(x => 
    { 
      x.images.portrait = this.portrait;
      x.rectangle = new Rectangle(250, 200, 50, 50, 0, 0, 'white');
    });

    console.log('chars', this.characters);


    this.units = [new Character('Cody', 100, 0, 50, 50, this.portrait), new Character('Ricardo', 150, 100, 50,50, this.unknownPortrait)];

    this.canvas = document.getElementById('QuestCanvas');
    this.ctx = this.canvas.getContext('2d');
    this.gameBounds = { r: this.canvas.width, l: 0, u: 0, d: this.canvas.height };
  },
  computed: {
  },
  methods: {

    gameMasterHandler () {
      if (this.gameView === 'TitleScreen') {
        this.runTitleScreen();
      } else if (this.gameView === 'MainGame') {
        this.runGame();
      } else if (this.gameView === 'GameOver') {
        this.runGameOver();
      }
    },

    initGame () {
    },

    runTitleScreen () {
      this.drawTitleScreen();
    },
    
    drawTitleScreen () {
      drawString(this.ctx, 'Dodgeball: Press "Enter" to play.', 150, 150);
    },

    runGame () {
      this.updateGame();
      this.drawGame();
    },
    
    updateGame () {
      this.updateCursor();

      this.characters.forEach(x => {
        if (x.rectangle.positionMatches(this.cursor.rectangle)) {
          console.log('name is ', x);
          this.portrait = x.images.portrait;
        }
      })
    },

    drawGame () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(this.background, 0, 0);
      this.ctx.drawImage(this.cursor.image, this.cursor.rectangle.xPos, this.cursor.rectangle.yPos);
      this.ctx.drawImage(this.characters[0].images.portrait, 300, 300);
    },

    async runGameOver () {
      this.gameView = 'TitleScreen';
    },

    updateCursor () {
      if (!this.keyDelay) {
        if(this.rightPressed) {
          if (this.cursor.rectangle.xPos < (this.gameBounds.r - 50)) {
            this.cursor.move(this.cursor.rectangle.xPos, this.cursor.rectangle.yPos, 50, 0, this.gameBounds);
            //document.getElementById('GameContainer').scrollLeft = this.cursor.rectangle.xPos;
            this.setKeyDelay();
          }
        } else if (this.leftPressed) {
          if (this.cursor.rectangle.xPos > (this.gameBounds.l)) {
            this.cursor.move(this.cursor.rectangle.xPos, this.cursor.rectangle.yPos, -50, 0, this.gameBounds);
            //document.getElementById('GameContainer').scrollRight = this.cursor.rectangle.xPos;
            this.setKeyDelay();
          }
        } else if (this.upPressed) {
          if (this.cursor.rectangle.yPos > (this.gameBounds.u)) {
            this.cursor.move(this.cursor.rectangle.xPos, this.cursor.rectangle.yPos, 0, -50, this.gameBounds);
            this.setKeyDelay();
          }
        } else if (this.downPressed) {
          if (this.cursor.rectangle.yPos < (this.gameBounds.d - 50)) {
            this.cursor.move(this.cursor.rectangle.xPos, this.cursor.rectangle.yPos, 0, 50, this.gameBounds);
            this.setKeyDelay();
          }
        }
      }
    },

    runBattleAnimation (firstAnimation, secondAnimation) {
      for(let index = 0; index < firstAnimation.frames; index++) {
        setTimeout(() => {
          //animation.draw();
          //image, source (which cordinates to take from image), destination, where to draw
          this.ctx.drawImage(firstAnimation.image, 0, 0, 50, 50, 100, 100, 50, 50);
        }, (firstAnimation.frameSpeed * firstAnimation.frames));
      }

      for(let index = 0; index < secondAnimation.frames; index++) {
        setTimeout(() => {

        }, (secondAnimation.frameSpeed * secondAnimation.frames));
      }
    },

    setKeyDelay () {
      this.keyDelay = true;
      setTimeout(() => { this.keyDelay = false; }, 200);
    },

    keyDownHandler (e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = true;
      } else if (e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = true;
      } else if (e.key == "Up" || e.key == "ArrowUp") {
        this.upPressed = true;
      } else if (e.key == "Down" || e.key == "ArrowDown") {
        this.downPressed = true;
      }
    },

    keyUpHandler (e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = false;
      }
      if(e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = false;
      }
      if(e.key == "Up" || e.key == "ArrowUp") {
        this.upPressed = false;
      }
      if(e.key == "Down" || e.key == "ArrowDown") {
        this.downPressed = false;
      }
      if(e.key == "Enter" && this.gameView === 'TitleScreen') {
        this.initGame();
        this.gameView = 'MainGame';
      }
    }
  }
}
</script>

<style lang="scss">

  .quest-page {
    padding-left: 2em;
    padding-right: 2em;

    .header {
      font-size: 2.25em;
      text-align: center;
    }

    .body {
      display: flex;
      flex-direction: row;
      width: 100%;

      .column {
        text-align: center;

        &.left {
          display: flex;
          flex-direction: column;
          width: 20%;

          .details {
            width: 100%;

            .top {
              width: 100%;
            }

            .bottom {
              padding-top: 3em;
              width: 100%;
            }
          }
        }

        &.right {
          width: 90%;
          
          .game-container {
            //width: 80%;
            height: 100%;
            -ms-overflow-style: none;
            scrollbar-width: 0;
            overflow: auto;
            background: black;
            text-align: left;
          
            .game-canvas {
              background: #eee;
              margin: 2em;
            }
          }

          div::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        }
      }
    }
  }

</style>
