<template>
  <article class="nutrition-goals-page">
    <section class="header">
      <div class="left">
        <span @click="setCurrentGoal" class="selected-goal-type">{{ goalToView }} </span>
      </div>
      <div class="right">
        <span>Nutrition Goal</span>
      </div>
    </section>
    <div v-for="(nutrientGoal, index) in currentGoals" :key=index class="nutrition-goal-area">
      <div class="nutrition-goal-display">
        <div class="nutrition-goal">
          <p class="goal-header">{{ nutrientGoal.displayName }}</p>
          <div class="goal">
            <p class="goal-text">Goal: {{ nutrientGoal.goal.total }}{{ nutrientGoal.measurementAbbreviation }} <mdicon name="pencil" /></p>
            <p class="goal-text">Progress: {{ nutrientGoal.goal.progress }}{{ nutrientGoal.measurementAbbreviation }} </p>
            <p class="goal-text">Remaining: {{ nutrientGoal.goal.remaining }}{{ nutrientGoal.measurementAbbreviation }} </p>

            <div class="goal-progress-area">
              <div class="goal-progress" :style="{ width: nutrientGoal.goal.percentComplete + '%', 'background-color': getGoalColor(nutrientGoal.goalIsHigh, nutrientGoal.goal)}">
                <span class="goal-progress-percent-text"> {{ nutrientGoal.goal.percentComplete }}% </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  
</template>

<script>
import axios from "axios";

export default {
  name: 'nutrition-goals',
  data () {
    return {
      userGoals: [],
      goalToView: '',
      currentGoals: []
    }
  },
  mounted () {
    this.getUserNutritionGoals();
  },
  computed: {
    
  },
  methods: {
    async getUserNutritionGoals () {
      await axios.get('https://localhost:7241/api/AppNutritionGoals?userid=CSirk').then(response => 
        { 
          this.userGoals = response.data; 
          this.setCurrentGoal();
        }
      );
    },
    setCurrentGoal () {
      this.currentGoals = [];

      if (this.goalToView === 'Daily') {
        this.goalToView = 'Weekly';
      }
      else if (this.goalToView === 'Weekly') {
        this.goalToView = 'Monthly';
      }
      else {
        this.goalToView = 'Daily';
      }

      let goal = {};

      this.userGoals.forEach(n => { 
        switch (this.goalToView) {
          case 'Daily': goal = n.dailyGoal; break;
          case 'Weekly': goal = n.weeklyGoal; break;
          case 'Monthly': goal = n.monthlyGoal; break;
        }
        
        this.currentGoals.push(
        {
          nutrientName: n.nutrientName, displayName: n.displayName, displayOrder: n.displayOrder, goalIsHigh: n.goalIsHigh, 
          measurement: n.measurement, measurementAbbreviation: n.measurementAbbreviation, goal: goal
        });
      });
    },
    getGoalColor (goalIsHigh, goal) {
      if (goalIsHigh && goal.percentComplete === 100) {
        return 'limegreen';
      } else if (!goalIsHigh && goal.percentComplete === 100) {
        return 'red';
      }
      return '#0000FF';
    }
  }

}
</script>

<style lang="scss">

  .nutrition-goals-page {
    padding-left: 2em;
    padding-right: 2em;

    .header {
      display: flex;
      font-size: 2.25em;

      .left {
        width: 100%;
        text-align: right;
        padding-right: 0.25em;

        .selected-goal-type {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-decoration-color: #0000FF;
          cursor: pointer;
        }
      }

      .right {
        width: 100%;
        padding-left: 0.25em;
      }
    }

    .nutrition-goal-area {

      @media only screen and (min-width: 90em)
      {
        padding-bottom: 3em;
        display: inline-block;
        width: 20%;
      }

      @media only screen and (min-width: 70em) and (max-width: 90em)
      {
        padding-bottom: 3em;
        display: inline-block;
        width: 25%;
      }

      @media only screen and (min-width: 40em) and (max-width: 70em)
      {
        padding-bottom: 3em;
        display: inline-block;
        width: 50%;
      }

      .nutrition-goal-display {
        margin: 0 auto;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);

        .nutrition-goal {
          text-align: center;

          .goal-header {
            background-color: white;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-decoration-color: #0000FF;
            font-size: 2.25em;
            padding-top: 0.25em;
          }

          .goal-text {
            font-size: 1.5em;
          }

          .goal-progress-area {
            width: 100%;
            background-color: lightskyblue;

            .goal-progress {
              height: 1.75em;

              .goal-progress-percent-text {
                color: white;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

</style>
