<template>
  <header>
      <nav>
        <div class="nav-links">
          <router-link to="/" class="home-link">Home</router-link>
          <router-link to="/about" class="about-link">About</router-link>
        </div>
      </nav>
  </header>

  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss">

  body {
    margin: 0;
  }

  .nav-links {
    background-color: blue;
    overflow: hidden;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    height: 2rem;

    .home-link {
      text-align: left;
      color: red;
    }

    .about-link {
      padding-left: 3em;
    }
  }

  header {
    // padding-bottom: 2em;
  }

</style>
