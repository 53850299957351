<template>
  <article class="code-page">
    <!-- <select v-model="selectedOption" @change="changeSelectedGuide()">
      <option v-for="(guide, index) in guides" :key=index :value=guide.title>{{guide.title}}</option>
    </select> -->

    <div v-if="isLoading" class="loader"></div>

    
    <section class="guides" v-if="!isLoading">

      <section class="column left">
        <label>Type</label>
        <input type="text" v-model="article.type" />

        <label>Title</label>
        <input type="text" v-model="article.title" />

        <label>Description</label>
        <input type="text" v-model="article.description" />

        <label>Category</label>
        <input type="text" v-model="article.category" />

        <label>SubCategory</label>
        <input type="text" v-model="article.subCategory" />

        <div>
          <label>Submit</label>
        </div>
        <button @click="upsertGuide">Save Guide</button>
      </section>

      <section class="column center">
        <section>
          <div>
            <label>Article Setup</label>
          </div>
          <textarea ref="setupTextArea" v-model="article.articleSetup.text" @keydown="handleKeyDownSetup" type="text"></textarea>
        </section>
      </section>

      <section class="column right">
        <section>
          <div>
            <label>Article Example</label>
          </div>
          <div>
            <textarea ref="exampleTextArea" v-model="currentExampleText" @keydown="handleKeyDownExample" type="text"></textarea>
          </div>
           <div>
            <label>Description</label>
          </div>
          <div>
            <input type="text" v-model="currentExampleDescription" class="description"/>
          </div>
          <div>
            <label>Add Example</label>
            <div>
              <button @click="addExample">Add Example</button>
            </div>
          </div>
        </section>
      </section>
    </section>
  </article>
</template>

<script>
import axios from "axios";

export default {
  name: 'code-guides',
  data () {
    return {
      article: {
        id: 0, type: '', title: '', description: '', 
        category: '', subCategory: '',
        articleSetup: { id: 0, articleId: 0, text: '', reservedWords: ''}, 
        articleExamples: [{ id: 0, articleId: 0, description: '', text: ''}]
      },
      currentSetupText: '',
      currentExampleText: '',
      currentExampleDescription: '',
      isLoading: false,
      examplesToUpsert: []
    }
  },
  async mounted () {
  },
  computed: {
    
  },
  methods: {
    async upsertGuide () {
      this.isLoading = true;
      this.article.articleExamples = this.examplesToUpsert;
      await axios.put('https://streetsofsmashvilleapi.azurewebsites.net/api/apparticle', this.article);
      this.article = {
        id: 0, type: '', title: '', description: '', 
        category: '', subCategory: '',
        articleSetup: { id: 0, articleId: 0, text: '', reservedWords: ''}, 
        articleExamples: [{ id: 0, articleId: 0, description: '', text: ''}]
      }
      this.currentExampleText = '';
      this.currentExampleDescription = '';
      this.isLoading = false;
    },
    addExample () {
      this.examplesToUpsert.push(
        ...this.article.articleExamples, 
        {id: 0, articleId: this.article.id, description: this.currentExampleDescription, text: this.currentExampleText}
      );
      this.currentExampleText = '';
      this.currentExampleDescription = '';
    },
    handleKeyDownSetup (e) {
      if (e.code === "Tab") {
        e.handled = true;
        e.preventDefault();
        const textArea = this.$refs.setupTextArea;
        const originalPosition = textArea.selectionStart;

        this.article.articleSetup.text = this.article.articleSetup.text.slice(0, textArea.selectionStart) + '\t' + this.article.articleSetup.text.slice(textArea.selectionStart);
        this.$nextTick(() => {
          textArea.setSelectionRange(originalPosition + 1, originalPosition + 1);
        });
      }
    },
    handleKeyDownExample (e) {
      if (e.code === "Tab") {
        e.handled = true;
        e.preventDefault();
        const textArea = this.$refs.exampleTextArea;
        const originalPosition = textArea.selectionStart;

        this.currentExampleText = this.currentExampleText.slice(0, textArea.selectionStart) + '\t' + this.currentExampleText.slice(textArea.selectionStart);
        this.$nextTick(() => {
          textArea.setSelectionRange(originalPosition + 1, originalPosition + 1);
        });
      }
    }
  }
}
</script>

<style lang="scss">

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  /* width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: blue; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  body {
    overflow: hidden;

    
  }
  
  .code-page {
    font-size: 1.5em;
    margin-top: 2rem;

    .code-header {
      background: black;
      color: white;

      .header-title {
        padding-right: 2em;

        &.selected {
          color: white;
          background: blue;
        }
      }
    }

    .guides {
      display: flex;
      flex-direction: row;

      .column {
        height: 100vh;

        &.left {
          width: 10%;
          border-right: 10px solid black;
          margin-right: 1em;

          //padding-right: 1em;
          overflow-y: auto;

          .menu-entry {

            .category {
              padding-top: 0.5em;
              text-align: center;
              background: rgb(43, 43, 196);

            }
            
            .subcategory {
              background: rgb(134, 143, 182);

              &.hidden {
                display: none;
              }
            }
          }
        }

        &.center {
          width: 45%;

          textarea {
            width: 90%;
            max-width: 90%;
            height: 20em;
          }
        }

        &.right {
          width: 45%;
          padding-right: 0.25em;
          overflow-y: auto;

          textarea {
            width: 90%;
            max-width: 90%;
            height: 20em;
          }

          .description {
            width: 90%;
          }
        }
      }
    }
  }
</style>
