import axios from "axios";

let reboundSoundEffect = new Audio(require(`../../../assets/games/media/audio/rebound.mp3`));
let winSoundEffect = new Audio(require(`../../../assets/games/media/audio/win.mp3`));


export class Rectangle {
  constructor (xPos, yPos, width, height, speedX, speedY, color) {
    this.xPos = xPos;
    this.yPos = yPos;
    this.width = width;
    this.height = height;
    this.speedX = speedX;
    this.speedY = speedY;
    this.color = color;
  }

  draw (ctx) {
    ctx.beginPath();
    ctx.rect(this.xPos, this.yPos, this.width, this.height) 
    ctx.fillStyle = this.color;
    ctx.fill();
    ctx.closePath();
  }

  move (xMove, yMove, bounds) {
    if(!this.isInBounds('R', bounds.r)) {
      this.xPos = bounds.r - this.width;
    } else if (!this.isInBounds('L', bounds.l)) {
      this.xPos = bounds.l;
    } else if (!this.isInBounds('U', bounds.u)) {
      this.yPos = bounds.u;
    } else if (!this.isInBounds('D', bounds.d)) {
      this.yPos = bounds.d - this.height;
    }

    this.xPos += xMove;
    this.yPos += yMove;
  }

  moveNoBounds (xMove, yMove) {
    this.xPos += xMove;
    this.yPos += yMove;
  }

  intersects (shape) {
    if (shape instanceof Rectangle) {
      return this.xPos >= shape.xPos && this.xPos <= shape.xPos + shape.width // X is within
        && this.yPos >= shape.yPos && this.yPos <= shape.yPos + shape.height // Y is within
    } else if (shape instanceof Circle) {
      return this.xPos - shape.radius <= shape.xPos && this.xPos + this.width + shape.radius >= shape.xPos
        && this.yPos >= shape.yPos && this.yPos <= shape.yPos + shape.radius;
    }
  }

  intersectsAny (shapes) {
    shapes.foreach(x => {
      if (this.intersects(x)) {
        return x;
      }
    })
  }

  positionMatches (shape) {
    if (shape instanceof Rectangle) {
      return this.xPos == shape.xPos && this.yPos == shape.yPos; 
    } else if (shape instanceof Circle) {
      return this.xPos == shape.xPos && this.yPos == shape.yPos;
    }
  }

  positionMatchesAny (shapes) {
    shapes.foreach(x => {
      if (this.positionMatches(x)) {
        return x;
      }
    })
  }

  isInBounds (direction, position) {
    if (direction === 'R') {
      return this.xPos + this.width < position;
    } else if (direction === 'L') {
      return this.xPos > position;
    } else if (direction === 'U') {
      return this.yPos > position;
    } else if (direction === 'D') {
      return this.yPos + this.height < position;
    }
  }
}

export class Circle {
  constructor (xPos, yPos, radius, speedX, speedY, color) {
    this.xPos = xPos,
    this.yPos = yPos,
    this.radius = radius,
    this.speedX = speedX,
    this.speedY = speedY,
    this.color = color
  }

  draw (ctx) {
    ctx.beginPath();
    ctx.arc(this.xPos, this.yPos, this.radius, 0, Math.PI*2);
    ctx.fillStyle = this.color;
    ctx.fill();
    ctx.closePath();
  }

  intersects (shape) {
    if (shape instanceof Circle) {
      return this.xPos >= shape.xPos && this.xPos <= shape.xPos + (shape.radius * 2)
        && this.yPos >= shape.yPos && this.yPos <= shape.yPos + (shape.radius * 2);
    }
  }
  
  isInBounds (direction, position) {
    if (direction === 'R') {
      return this.xPos + this.radius < position;
    } else if (direction === 'L') {
      return this.xPos > position;
    } else if (direction === 'U') {
      return this.yPos > position;
    } else if (direction === 'D') {
      return this.yPos + this.radius < position;
    }
  }
}

export function drawString (ctx, text, posX, posY, color) {
  ctx.font = "16px Arial";
  ctx.fillStyle = color;
  ctx.fillText(text, posX, posY);
}


export function playSoundEffect (name) {
  if (name === 'rebound') {
    reboundSoundEffect.play();
  } else if (name === 'win') {
    winSoundEffect.play();
  }
}

export async function getHighScores (gameName) {
  const response = await axios.get(`https://streetsofsmashvilleapi.azurewebsites.net/high-scores?gameName=${gameName}`);
  return response.data;
}

export async function submitHighScore (highScores) {
  await axios.post('https://streetsofsmashvilleapi.azurewebsites.net/high-scores', highScores);
}
