import { Rectangle } from '../shared/ccada';

export default class Cursor {
  constructor (image, xPos, yPos, width, height) {
    this.image = image;
    this.rectangle = new Rectangle(xPos, yPos, width, height, 50, 50, 'white');
  }

  draw (ctx) {
    this.rectangle.draw(ctx);
    ctx.drawImage(this.image, this.rectangle.xPos, this.rectangle.yPos)
  }

  move (xPos, yPos, xMove, yMove) {
    
    // if (xMove > 0 && xPos < (bounds.r - 50)) {
    //   console.log('moving right');
    //   this.rectangle.move(xMove, yMove, bounds);
    // } else if (xMove < 0 && (xPos > bounds.l)) {
    //   console.log('moving left');
    //   this.rectangle.move(xMove, yMove, bounds);
    // } else if (yMove > 0 && (yPos < (bounds.d + 50))) {
    //   console.log('moving down');
    //   this.rectangle.move(xMove, yMove, bounds);
    // } else if (yMove < 0 && (yPos > bounds.u)) {
    //   console.log('moving up');
    //   this.rectangle.move(xMove, yMove, bounds);
    // }

    this.rectangle.moveNoBounds(xMove, yMove);
    console.log('curser x, y', this.rectangle.xPos + ',' + this.rectangle.yPos)
  }

  intersects (shape) {
    return this.rectangle.positionMatches(shape);
  }

  isInBounds (direction, position) {
    return this.rectangle.isInBounds(direction, position);
  }
}