<template>
  <h1>Test</h1>
  <router-link to="/games/rebound-game">Rebound</router-link>
  <router-link to="/games/dodgeball-game">Dodgeball</router-link>
  <router-link to="/games/dodgeball-xl-game">Dodgeball XL</router-link>
  <router-link to="/games/hackman-game">Hackman</router-link>



</template>

<script>

export default {
  name: 'App',
  data () {
    return {

    }
  },
  methods: {

  }

}
</script>

<style lang="scss">

</style>
