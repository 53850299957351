<template>
  <article class="rebound-page">
    <section class="header">
      <span>Rebound</span>
    </section>

    <section class="body">

      <span class="column">
        <span class="high-score-display">
          <h1>High Scores</h1>
          <span v-for="(score, index) in highScores.scores" :key=index class="score-section">
            <div class="award-info">
              <img :src="getAwardImage(index)" />
              <p>{{ score.userId }} : {{ score.score }}</p>
            </div>
          </span>
        </span>
        
      </span>

      <span class="column">
        <span class="game">
          <canvas id="ReboundCanvas" class="game-canvas" width="480" height="320"></canvas>
        </span>

        <span>
          <h3>Enter Name:</h3>
          <input type="text" v-model="userId" />
        </span>
      </span>
      

      <span class="column">
        
      </span>
    </section>

    

  </article>
  
</template>

<script>
import axios from "axios";

export default {
  name: 'rebound-game',
  data () {
    return {
      highScores: {},
      canvas: {},
      ctx: {},
      baseBall: {},
      basePaddle: {},
      ball: {},
      player: {},
      computer: {},
      userIdEntered: false,
      titleScreenActive: true,
      gameStateReset: false,
      rightPressed: false,
      leftPressed: false,
      mainGameActive: false,
      gameOver: false,
      score: 0,
      topScore: 0,
      lastModIncrease: 0,

      awards: ['Onyx', 'Diamond', 'Gold', 'Silver', 'Bronze'],

      userId: 'Anonymous',

      pingSoundEffect: new Audio(require(`../../assets/games/media/audio/ping.mp3`)),
      reboundSoundEffect: new Audio(require(`../../assets/games/media/audio/rebound.mp3`)),
      winSoundEffect: new Audio(require(`../../assets/games/media/audio/win.mp3`)),
      bronzeTrophy: require('../../assets/games/media/images/Bronze.png'),
      silverTrophy: require('../../assets/games/media/images/Silver.png'),
      goldTrophy: require('../../assets/games/media/images/Gold.png'),
      diamondTrophy: require('../../assets/games/media/images/Diamond.png'),
      onyxTrophy: require('../../assets/games/media/images/Onyx.png'),
    }
  },
  async mounted () {
    setInterval(this.gameMasterHandler, 10);
    document.addEventListener("keydown", this.keyDownHandler, false);
    document.addEventListener("keyup", this.keyUpHandler, false);
    this.canvas = document.getElementById('ReboundCanvas');
    this.ctx = this.canvas.getContext('2d');
    this.awards = [this.onyxTrophy, this.diamondTrophy, this.goldTrophy, this.silverTrophy, this.bronzeTrophy]

    await this.getHighScores();
  },
  computed: {
  },
  methods: {
    async getHighScores () {
      const response = await axios.get('https://streetsofsmashvilleapi.azurewebsites.net/high-scores?gameName=Rebound');
      this.highScores = response.data;
    },

    async submitHighScore () {
      this.highScores.scores.push({ userId: this.userId, score: this.score });
      this.titleScreenActive = true;
      await axios.post('https://streetsofsmashvilleapi.azurewebsites.net/high-scores', this.highScores);
    },

    gameMasterHandler () {
      if (this.titleScreenActive) {
        this.runTitleScreen() 
      } else if (this.mainGameActive) {
        this.runGame();
      } else if (this.gameOver) {
        this.runGameOver();
      }
    },

    getAwardImage (index) {
      console.log(this.awards[index]);
      return `${this.awards[index]}`;
    },

    initGame () {
      this.score = 0;
      this.lastModIncrease = 0;
      this.baseBall = { radius: 10, posX: this.canvas.width / 2, posY: this.canvas.height / 2, speedX: 2, speedY: -2 };
      this.basePaddle = { width: 75, height: 10, speed: 4, powerX: 2, powerY: 2 };

      this.initBall();
      this.initPaddles();
      this.titleScreenActive = false;
    },

    runTitleScreen () {
      this.drawTitleScreen();
    },

    runGame () {
      this.updateGame();
      this.drawGame();
    },

    async runGameOver () {
      this.gameOver = false;
      
      if (this.score > this.topScore) {
        this.topScore = this.score;
      }

      await this.getHighScores();
      if ((this.score > this.highScores.lowestScore)) {
        await this.submitHighScore();
        await this.getHighScores();
      }
      this.titleScreenActive = true;
    },

    updateGame () {
      this.updateBall();
      this.updatePlayer();
      this.updateComputer();
    },

    drawTitleScreen () {
      this.drawString('Rebound: Press "F" to play.', 150, 150);
    },

    drawNameEntry () {
      this.drawString('Enter Name', 150, 150);
    },

    drawGame () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawBall();
      this.drawPaddles();
      this.drawScore();
    },

    initBall () {
      this.ball = { radius: this.baseBall.radius, posX: this.canvas.width / 2, posY: this.canvas.height / 2, speedX: this.baseBall.speedX, speedY: this.baseBall.speedY }
    },

    initPaddles () {
      this.player = { 
        paddleX: this.canvas.width / 2, paddleY: this.canvas.height - 10, paddleWidth: this.basePaddle.width, paddleHeight: this.basePaddle.height, 
        paddleSpeed: this.basePaddle.speed, powerX: this.basePaddle.powerX, powerY: this.basePaddle.powerY, ballRadius: this.baseBall.radius
      };

      this.computer = {
        paddleX: this.canvas.width / 2, paddleY: 0, paddleWidth: this.basePaddle.width, paddleHeight: this.basePaddle.height, 
        paddleSpeed: this.basePaddle.speed, powerX: this.basePaddle.powerX, powerY: this.basePaddle.powerY, ballRadius: this.baseBall.radius
      };
    },

    updateComputer () {
      //right movement
      if (((this.computer.paddleX + this.computer.paddleWidth)) < this.ball.posX && (this.computer.paddleX + this.computer.paddleWidth + this.computer.paddleSpeed) < this.canvas.width) {
        this.computer.paddleX += this.computer.paddleSpeed;
        //left movement
      } else if (((this.computer.paddleX + this.computer.paddleWidth)) > this.ball.posX && (this.computer.paddleX - this.computer.paddleSpeed) > 0) {
        this.computer.paddleX -= this.computer.paddleSpeed;
      }
    },

    updatePlayer () {
      if(this.rightPressed) {
          this.player.paddleX += this.player.paddleSpeed;
          if (this.player.paddleX + this.player.paddleWidth > this.canvas.width){
              this.player.paddleX = this.canvas.width - this.player.paddleWidth;
          }
      }
      else if(this.leftPressed) {
        this.player.paddleX -= this.player.paddleSpeed;
        if (this.player.paddleX < 0){
          this.player.paddleX = 0;
        }
      }
    },

    updateBall () {
      // right and left
      if(
        this.ball.posX + this.ball.speedX > this.canvas.width - this.ball.radius || this.ball.posX + this.ball.speedX < this.ball.radius) {
        this.playSoundEffect('rebound');
        this.ball.speedX = -this.ball.speedX;
      }
      // Top
      if(this.ball.posY + this.ball.speedY < this.ball.radius) {
          //enemy paddle collision
        if (this.ball.posX >= this.computer.paddleX - this.ball.radius && this.ball.posX <= this.computer.paddleX + this.computer.paddleWidth + this.ball.radius) {
          this.ball.speedY = -this.ball.speedY;
          this.playSoundEffect('ping');
        } else {
          this.playSoundEffect('win');
          this.score += 500;
          this.ball.speedY = -this.ball.speedY
        }
      }
      //paddle collision / bottom
      if ((this.ball.posY + this.ball.speedY) > this.canvas.height - this.ball.radius) {
        if (this.ball.posX >= this.player.paddleX - this.ball.radius && this.ball.posX <= this.player.paddleX + this.player.paddleWidth + this.ball.radius) {
          this.playSoundEffect('ping');
          this.ball.speedY = -this.ball.speedY;
          this.score += 100;
          
          if (this.score % 1000 === 0 && this.lastModIncrease != this.score) {
            this.lastModIncrease = this.score;
            this.ball.speedX = (this.ball.speedX > 0) ? this.ball.speedX + 1 : this.ball.speedX - 1;
            this.ball.speedY = (this.ball.speedY > 0) ? this.ball.speedY + 1 : this.ball.speedY - 1;

            if (this.score % 2000 === 0) {
              this.player.paddleSpeed += 1;
              this.computer.paddleSpeed += 1;
            }
          }
        } else {
          this.playSoundEffect('win');
          this.gameOver = true;
          this.mainGameActive = false;
        }
      }
      
      this.ball.posX += this.ball.speedX;
      this.ball.posY += this.ball.speedY;
    },

    drawBall () {
      this.drawCircle(this.ball.posX, this.ball.posY, this.ball.radius, '#000000');
    },

    drawPaddles () {
      this.drawRectangle(this.player.paddleX, this.player.paddleY, this.player.paddleWidth, this.player.paddleHeight, '#0000FF');
      this.drawRectangle(this.computer.paddleX, this.computer.paddleY, this.computer.paddleWidth, this.computer.paddleHeight, '#ff0000');
    },

    drawString (text, posX, posY, color) {
      this.ctx.font = "16px Arial";
      this.ctx.fillStyle = color;
      this.ctx.fillText(text, posX, posY);
    },

    drawScore () {
      this.drawString(`Score: ${this.score}`, 8, 20, '#0095DD');
      this.drawString(`High Score: ${this.topScore}`, 300, 20, '#0095DD');
    },

    keyDownHandler(e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = true;
      } else if(e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = true;
      }
    },

    keyUpHandler(e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = false;
      }
      if(e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = false;
      }
      if(e.key == "f" || e.key == "KeyF" && this.titleScreenActive) {
        this.initGame();
        this.titleScreenActive = false;
        this.mainGameActive = true;
      }
    },

    drawRectangle (x, y, width, height, color) {
      this.ctx.beginPath();
      this.ctx.rect(x, y, width, height, color) 
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.closePath();
    },

    drawCircle (x, y, radius, color) {
      this.ctx.beginPath();
      this.ctx.arc(x, y, radius, 0, Math.PI*2);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.closePath();
    },

    playSoundEffect (name) {
      if (name === 'ping') {
        this.pingSoundEffect.play();
      } else if (name === 'rebound') {
        this.reboundSoundEffect.play();
      } else if (name === 'win') {
        this.winSoundEffect.play();
      }
    },
  }
}
</script>

<style lang="scss">

  .rebound-page {
    padding-left: 2em;
    padding-right: 2em;

    .header {
      font-size: 2.25em;
      text-align: center;
    }

    .body {
      display: flex;
      flex-direction: row;

      .column {
        width: 100%;
        text-align: center;

        .high-score-display {
          width: 100%;

          .score-section {
            
          }

          .high-score {
            width: 100%;

          }
        }

        .game {
          text-align: center;
          
          .game-canvas {
            background: #eee;
            margin-top: 2em;
          }
        }
      }
    }
  }

</style>
