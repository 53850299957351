<template>
  <article class="hackman-page">
    <section class="header">
      <span>{{ gameName }}</span>
    </section>

    <section class="body">

      <span class="column">
        <span class="high-score-display">
          <h1>High Scores</h1>
          <span v-for="(score, index) in highScores.scores" :key=index class="score-section">
            <div class="award-info">
              <img :src="getAwardImage(index)" />
              <p>{{ score.userId }} : {{ score.score }}</p>
            </div>
          </span>
        </span>
        
      </span>

      <span class="column">
        <span class="game">
          <canvas id="HackmanCanvas" class="game-canvas" width="600" height="800"></canvas>
        </span>

        <span>
          <h3>Enter Name:</h3>
          <input type="text" v-model="userId" />
        </span>
      </span>

      <span class="column"></span>
    </section>
  </article>
  
</template>

<script>
import { Circle, Rectangle, drawString, getHighScores, submitHighScore, playSoundEffect } from "./shared/ccada";

export default {
  name: 'hackman-game',
  data () {
    return {
      highScores: {},
      canvas: {},
      ctx: {},
      gameBounds: {},
      ball: Circle,
      player: Rectangle,
      gameView: 'TitleScreen',
      userId: 'Anonymous',
      gameName: 'Hackman',

      rightPressed: false,
      leftPressed: false,
      score: 0,
      topScore: 0,
      lastModIncrease: 0,
      awards: ['Onyx', 'Diamond', 'Gold', 'Silver', 'Bronze'],

      bronzeTrophy: require('../../assets/games/media/images/Bronze.png'),
      silverTrophy: require('../../assets/games/media/images/Silver.png'),
      goldTrophy: require('../../assets/games/media/images/Gold.png'),
      diamondTrophy: require('../../assets/games/media/images/Diamond.png'),
      onyxTrophy: require('../../assets/games/media/images/Onyx.png'),
    }
  },
  async mounted () {
    setInterval(this.gameMasterHandler, 10);
    document.addEventListener("keydown", this.keyDownHandler, false);
    document.addEventListener("keyup", this.keyUpHandler, false);
    this.canvas = document.getElementById('HackmanCanvas');
    this.ctx = this.canvas.getContext('2d');
    this.awards = [ this.onyxTrophy, this.diamondTrophy, this.goldTrophy, this.silverTrophy, this.bronzeTrophy ]
    this.gameBounds = { r: this.canvas.width, l: 0, u: 0, d: this.canvas.height };
    this.highScores = await getHighScores('Hackman');
  },
  computed: {
  },
  methods: {

    gameMasterHandler () {
      if (this.gameView === 'TitleScreen') {
        this.runTitleScreen();
      } else if (this.gameView === 'MainGame') {
        this.runGame();
      } else if (this.gameView === 'GameOver') {
        this.runGameOver();
      }
    },

    getAwardImage (index) {
      return `${this.awards[index]}`;
    },

    initGame () {
      this.score = 0;
      this.lastModIncrease = 0;
      this.initBall();
      this.initPaddle();
    },

    initBall () {
      this.ball = new Circle(this.canvas.width / 2, this.canvas.height / 2, 10, 3, 3, '#000000')
    },

    initPaddle () {
      this.player = new Rectangle(this.canvas.width / 2, this.canvas.height - 10, 75, 10, 2, 2, '#0000FF')
    },

    runTitleScreen () {
      this.drawTitleScreen();
    },
    
    drawTitleScreen () {
      drawString(this.ctx, 'Hackman: Press "Enter" to play.', 150, 150);
    },

    runGame () {
      this.updateGame();
      this.drawGame();
    },
    
    updateGame () {
      this.updateBall();
      this.updatePlayer();
    },

    drawGame () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ball.draw(this.ctx);
      this.player.draw(this.ctx);
      this.drawScore();
    },

    async runGameOver () {
      this.gameView = 'TitleScreen';
      
      if (this.score > this.topScore) {
        this.topScore = this.score;
      }

      this.highScores = await getHighScores(this.gameName);

      if ((this.score > this.highScores.lowestScore)) {
        this.highScores.scores.push({ userId: this.userId, score: this.score });

        await submitHighScore(this.highScores);
        this.highScores = await getHighScores(this.gameName);
      }
    },

    updatePlayer () {
      if(this.rightPressed) {
        this.player.move(this.player.speedX, 0, this.gameBounds);
      } else if(this.leftPressed) {
        this.player.move(-this.player.speedX, 0, this.gameBounds);
      }
    },

    updateBall () {
      if (!this.ball.isInBounds('R', this.gameBounds.r) 
        || !this.ball.isInBounds('L', this.gameBounds.l)) {
        playSoundEffect('rebound');
        this.ball.speedX = -this.ball.speedX;
      }

      if(!this.ball.isInBounds('U', this.gameBounds.u)) {
        playSoundEffect('rebound');
        this.ball.speedY = -this.ball.speedY;
      }

      //paddle collision / bottom
      if (!this.ball.isInBounds('D', this.gameBounds.d)) {
        if (this.player.intersects(this.ball)) {
          playSoundEffect('win');
          this.gameView = 'GameOver';
        } else {
          this.score += 100;
          playSoundEffect('rebound');
          this.ball.speedY = -this.ball.speedY;

          if (this.score % 1000 === 0 && this.lastModIncrease != this.score) {
            this.lastModIncrease = this.score;
            this.ball.speedX = (this.ball.speedX > 0) ? this.ball.speedX + 1 : this.ball.speedX - 1;
            this.ball.speedY = (this.ball.speedY > 0) ? this.ball.speedY + 1 : this.ball.speedY - 1;

            if (this.score % 2000 === 0) {
              this.player.speedX += 1;
            }
          }
        }
      }
      
      this.ball.xPos += this.ball.speedX;
      this.ball.yPos += this.ball.speedY;
    },

    drawScore () {
      drawString(this.ctx, `Score: ${this.score}`, 8, 20, '#0095DD');
      drawString(this.ctx, `High Score: ${this.topScore}`, 300, 20, '#0095DD');
    },

    keyDownHandler(e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = true;
      } else if(e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = true;
      }
    },

    keyUpHandler(e) {
      if(e.key == "Right" || e.key == "ArrowRight") {
        this.rightPressed = false;
      }
      if(e.key == "Left" || e.key == "ArrowLeft") {
        this.leftPressed = false;
      }
      if(e.key == "Enter" && this.gameView === 'TitleScreen') {
        this.initGame();
        this.gameView = 'MainGame';
      }
    }
  }
}
</script>

<style lang="scss">

  .hackman-page {
    padding-left: 2em;
    padding-right: 2em;

    .header {
      font-size: 2.25em;
      text-align: center;
    }

    .body {
      display: flex;
      flex-direction: row;

      .column {
        width: 100%;
        text-align: center;

        .high-score-display {
          width: 100%;

          .score-section {
            
          }

          .high-score {
            width: 100%;

          }
        }

        .game {
          text-align: center;
          
          .game-canvas {
            background: #eee;
            margin-top: 2em;
          }
        }
      }
    }
  }

</style>
